import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import CaptchaDisclaimer from 'components/captcha/disclaimer';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import AuthIllustration from 'illustrations/auth';
import { HTTPStatus, ReduxToolkitResponse } from 'types/http';
import { locators, QALocator } from 'utils/tests/QA';

import EmailForm from './emailForm';
import { FormFields, LABELS } from './keys';
import useStyles from './styles';
import useSignInForm from './useSignInForm';

export interface SignInScreenProps {
  authentication: {
    isLoading: boolean;
    isError: boolean;
  };
  initialEmail?: string;
  canGoBack: boolean;
  sendOtpEmail: (email: string) => Promise<ReduxToolkitResponse<{ isSuccess: boolean }> | null>;
  proceed: (email: string) => void;
  goBack: () => void;
  hideHeader?: boolean;
  errorMessage?: string;
}

const SignInScreen = ({
  authentication,
  sendOtpEmail,
  initialEmail,
  canGoBack,
  proceed,
  goBack,
  hideHeader,
  errorMessage,
}: SignInScreenProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const {
    form,
    canProceed,
  } = useSignInForm({
    initialEmail,
    errorTexts: {
      [FormFields.Email]: t(LABELS.REQUIRED),
    },
  });
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const isProceedDisabled = !canProceed || isProceedLoading;

  const onEmailChange = async (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(FormFields.Email, e.target.value);
  };

  const onContinue = async () => {
    setIsProceedLoading(true);
    const result = await sendOtpEmail(form.values[FormFields.Email]);
    if (result?.data?.data?.isSuccess) {
      proceed(form.values[FormFields.Email]);
      setIsProceedLoading(false);
      return;
    }

    if (result?.error?.status === HTTPStatus.BadRequest) {
      setIsProceedLoading(false);
      const defaultEmailError = t(LABELS.EMAIL_REGEX_ERROR);
      form.setFieldError(
        FormFields.Email,
        defaultEmailError,
      );
    }

    setIsProceedLoading(false);
  };

  return (
    <div className={cx(classes.container, { [classes.noHeader]: hideHeader })}>
      <OptionallyVisible visible={!hideHeader}>
        <Header
          hasBackButton={canGoBack}
          onBackClick={goBack}
        >
          {t(LABELS.HEADER)}
        </Header>
      </OptionallyVisible>
      <div className={classes.imageWrapper}>
        <AuthIllustration />
        {t(LABELS.LOGIN_TEXT)}
      </div>
      <EmailForm
        emailValue={form.values[FormFields.Email]}
        emailError={form.errors[FormFields.Email]}
        onEmailChange={onEmailChange}
      />
      <div className={classes.marginTopAuto}>
        <CaptchaDisclaimer />
        <OptionallyVisible visible={Boolean(errorMessage)}>
          <div className={classes.errorMessage}>{errorMessage || ''}</div>
        </OptionallyVisible>
        <Button
          isContinue
          onClick={onContinue}
          disabled={isProceedDisabled}
          isLoading={authentication.isLoading}
          {...QALocator(locators.page.signIn.proceedButton)}
        >
          {t(LABELS.LOGIN)}
        </Button>
      </div>
    </div>
  );
};

export default SignInScreen;
