import isRunningInsideIframe from 'utils/iframe';
import Logger from 'utils/logger';
import getNonceValue from 'utils/nonce';

export const initApplePay = () => {
  const isWrapped = isRunningInsideIframe();
  if (isWrapped) {
    return;
  }

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.nonce = getNonceValue();
  const applePayScriptSource = window.s4cConfig.applePay?.scriptSrc;

  if (!applePayScriptSource) {
    Logger.error('Missing Apple Pay script source.');
    return;
  }
  script.src = applePayScriptSource;

  head.appendChild(script);
};

export default initApplePay;
