import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import globalStyles from 'globalStyles';
import { GlobalStyles } from 'tss-react';

import CaptchaProvider from 'components/captcha';
import Layout from 'components/layout/root';
import initApplePay from 'libs/applePay/init';
import initSentry from 'libs/sentry/init';
import Boundary from 'pages/error/boundary';
import ParnterPageSpinner from 'pages/partner/spinner';
import Root from 'pages/root';
import MainRouter from 'pages/route';
import preload from 'preload';
import { store } from 'state/store';
import Flow from 'types/flow';
import { WorkflowStep } from 'types/step';
import { isPartnerPage } from 'utils/navigation';
import getNonceValue from 'utils/nonce';

import 'locales/i18next';

initSentry();
initApplePay();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const isLadingPage = isPartnerPage();

const cache = createCache({
  key: 'my-prefix-key',
  nonce: getNonceValue(),
  prepend: true,
});

root.render(
  isLadingPage
    ? (
      <ParnterPageSpinner />
    )
    : (
      <CaptchaProvider>
        <GlobalStyles styles={globalStyles} />
        <Layout showPartnersFooter>
          <Root
            step={WorkflowStep.Quote}
            flow={Flow.Buy}
            isLoading
          />
        </Layout>
      </CaptchaProvider>
    ),
);

preload(store).then(() => {
  root.render(
    <StrictMode>
      <CacheProvider value={cache}>
        <Provider store={store}>
          <ErrorBoundary
            FallbackComponent={Boundary}
          >
            <CaptchaProvider>
              <GlobalStyles styles={globalStyles} />
              <MainRouter />
            </CaptchaProvider>
          </ErrorBoundary>
        </Provider>
      </CacheProvider>
    </StrictMode>,
  );
});
