import { Trans, useTranslation } from 'react-i18next';

import Button from 'components/button';
import Header from 'components/header';
import OptionallyVisible from 'components/optionallyVisible';
import IconError from 'icons/error';
import ExchangeIllustration from 'illustrations/exchange';
import { LABELS as ERROR_LABELS } from 'locales/keys';
import { OrderStatus, OrderStepStatus } from 'types/transactions';
import { locators, QALocator } from 'utils/tests/QA';

import { getFailedPaymentSteps, LABELS } from './keys';
import { useStyles } from './styles';
import TransactionProcessingSteps from './transactionProcessingSteps';
import { TransactionProcessingPageProps } from './types';

const TransactionProcessingPage = ({
  flow,
  transactionStepsStatus,
  cardError,
  goBack,
  canGoBack,
  chainCode,
}: TransactionProcessingPageProps) => {
  const { classes, theme } = useStyles({ hasErrorTitle: Boolean(cardError?.title) });
  const { t } = useTranslation();
  const error = transactionStepsStatus.data?.steps.find(step => step.status === OrderStepStatus.Failed);
  const hasCardError = Boolean(cardError?.message);
  const hasError = Boolean(error) || hasCardError;
  const data = hasCardError
    ? { ...transactionStepsStatus?.data, steps: getFailedPaymentSteps(flow) }
    : transactionStepsStatus?.data;

  const renderImageWrapper = () => {
    if (hasError) {
      return (
        <div className={classes.imageWrapper}>
          <IconError color={theme.colors.icons.error} />
          {cardError?.title ?? t(ERROR_LABELS.GENERIC_TITLE)}
        </div>
      );
    }

    return (
      <div className={classes.imageWrapper}>
        <ExchangeIllustration />
        <Trans
          i18nKey={LABELS.PROCESSING_ORDER_REQUEST_NOTE}
          components={{
            br: <br />,
          }}
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Header hasBackButton={false}>
        {t(LABELS.HEADER)}
      </Header>
      {renderImageWrapper()}
      <OptionallyVisible visible={Boolean(data)}>
        <TransactionProcessingSteps
          flow={flow}
          data={data as OrderStatus}
          cardAuthorizationErrorMessage={cardError?.message}
          chainCode={chainCode}
        />
      </OptionallyVisible>
      <OptionallyVisible visible={canGoBack}>
        <div className={classes.marginTopAuto}>
          <Button
            isBack
            onClick={goBack}
            {...QALocator(locators.page.transactionProcessing.backButton)}
          >
            {t(LABELS.BACK)}
          </Button>
        </div>
      </OptionallyVisible>
    </div>
  );
};

export default TransactionProcessingPage;
