import { ReduxToolkitError } from 'types/http';

import { LABELS } from './keys';

const getCardErrorTitleMessageId = (error: ReduxToolkitError) => {
  if (error?.data?.meta?.shouldSolveBankIssue) {
    return LABELS.CONTACT_BANK_MESSAGE;
  }

  return undefined;
};

export default getCardErrorTitleMessageId;
