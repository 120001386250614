import { useDispatch, useSelector } from 'react-redux';

import buyPagesMap from 'navigation/mappings/buy';
import sellPagesMap from 'navigation/mappings/sell';
import { getPathParams } from 'pages/route/utils';
import { selectAvailablePaymentMethods, selectDefaultCardCacheEntry, selectPaymentMethod } from 'services/payments';
import { SavedCard } from 'services/payments/types';
import { selectOrderStatus } from 'services/transaction';
import { selectUserCacheEntry } from 'services/user';
import { selectFlow } from 'state/slices/flowSlice';
import { selectOrder } from 'state/slices/orderSlice';
import { selectCardError } from 'state/slices/paymentSlice';
import { changeStep, selectStep } from 'state/slices/stepSlice';
import { CardPaymentToken } from 'types/card';
import Flow from 'types/flow';
import { Order } from 'types/order';
import PaymentMethod from 'types/payment';
import { WorkflowStep } from 'types/step';
import { Transaction } from 'types/transactions';
import { Email, User } from 'types/user';
import PATHS from 'utils/navigation';

export interface NavigationEvaluationFuncParams {
  user?: User;
  email?: Email;
  tokenizedCard?: CardPaymentToken | SavedCard | null;
  flow?: Flow;
  order?: Order;
  orderStatus?: Transaction;
  isAddingCard?: boolean;
  hasCardError?: boolean;
  paymentMethod?: {
    default?: PaymentMethod | null;
    defaultCard?: SavedCard;
    availablePaymentMethods?: PaymentMethod[];
  };
}

export type NavigationEvaluationFunc<T> = (params: NavigationEvaluationFuncParams) => T

export interface ScreenNavigation {
  getNextStep: NavigationEvaluationFunc<WorkflowStep | WorkflowStep>;
  getPreviousStep: NavigationEvaluationFunc<WorkflowStep | WorkflowStep>;
  getCanGoBackValue: NavigationEvaluationFunc<boolean>;
}

export const useNavigation = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectStep);
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const flow = useSelector(selectFlow);
  const hasCardError = Boolean(useSelector(selectCardError)?.message);
  const pathParams = getPathParams(PATHS.CONTINUE_ORDER_PATH, window.location.pathname);
  const orderStatus = useSelector(selectOrderStatus(pathParams.orderId));
  const defaultPaymentMethod = useSelector(selectPaymentMethod);
  const order = useSelector(selectOrder);
  const pagesMap: { [key: string]: ScreenNavigation } = {
    [Flow.Buy]: buyPagesMap,
    [Flow.Sell]: sellPagesMap,
  }[flow];
  const defaultCard = useSelector(selectDefaultCardCacheEntry);
  const availablePaymentMethods = useSelector(selectAvailablePaymentMethods);
  const navigationParams: NavigationEvaluationFuncParams = {
    user,
    flow,
    paymentMethod: {
      default: defaultPaymentMethod,
      defaultCard,
      availablePaymentMethods,
    },
    orderStatus,
    hasCardError,
    order,
  };

  const canGoBack = pagesMap[currentStep]?.getCanGoBackValue({
    ...navigationParams,
  });

  const proceed = (params?: NavigationEvaluationFuncParams) => {
    const nextStep = pagesMap[currentStep]?.getNextStep({
      ...navigationParams, ...params,
    });

    if (nextStep === undefined) {
      throw new Error(`Cant get a next step for step: ${currentStep}`);
    }

    dispatch(changeStep(nextStep));
  };

  const goBack = (params?: NavigationEvaluationFuncParams) => {
    const previousStep = pagesMap[currentStep]?.getPreviousStep({
      ...navigationParams, ...params,
    });

    if (previousStep === undefined) {
      throw new Error(`Cant get previous step for step: ${currentStep}`);
    }

    dispatch(changeStep(previousStep));
  };

  return { proceed, goBack, canGoBack };
};

export default useNavigation;
