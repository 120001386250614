import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';
import { TransactionStatus } from 'types/transactions';
import { isFinishingOrder } from 'utils/navigation';

export const canGoBackFromTransactionProcessingStep = ({ hasCardError }: NavigationEvaluationFuncParams): boolean => {
  if (hasCardError) {
    return true;
  }

  return false;
};

export const getTransactionProcessingPreviousStepSell: NavigationEvaluationFunc<WorkflowStep> = (params) => {
  if (params.orderStatus?.status === TransactionStatus.AwaitingClientConfirmation) {
    return WorkflowStep.ConfirmNewQuote;
  }

  if (params.orderStatus?.status === TransactionStatus.PaymentFailed) {
    return WorkflowStep.RequestNewCard;
  }

  const isContinueFlow = isFinishingOrder();
  if (isContinueFlow && params.hasCardError) {
    return WorkflowStep.RequestNewCard;
  }

  if (params.hasCardError) {
    return WorkflowStep.BankDetails;
  }

  return WorkflowStep.Checkout;
};

export default getTransactionProcessingPreviousStepSell;
