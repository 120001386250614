import { SubscriptionOptions } from '@reduxjs/toolkit/src/query/core/apiState';

import { APIError } from './errors/api';

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum HTTPStatus {
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500
}

export interface Controller {
  abort: () => void;
}

export interface ControllerWithSubscription {
  abort: () => void;
  updateSubscriptionOptions(options: SubscriptionOptions): void;
  subscriptionOptions: SubscriptionOptions | undefined;
  unsubscribe(): void;
}

export interface ReduxToolkitResponse<T> {
  data?: Response<T>;
  error?: ReduxToolkitError;
}

export type ReduxToolkitApiCall<T> = (...args: any[]) => Promise<ReduxToolkitResponse<T>> & { abort: () => void }

export interface ReduxToolkitError {
  status?: number;
  name?: string;
  data?: object & {
    errorType?: string;
    errorMessage?: string;
    meta?: APIError['meta'];
  };
}

export interface Response<T> {
  data?: T;
  errorMessage?: string;
  errorType?: string;
}

export interface SuccessResponse {
  data: {
    success: boolean;
  };
}

export default HTTPMethod;
