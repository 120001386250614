import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import apiKeyMiddlewareHandlers from './handlers/apiKey';
import cardTokenMiddlewareHandlers from './handlers/cardToken';
import kycMiddlewareHandlers from './handlers/kyc';
import orderMiddlewareHandlers from './handlers/order';
import sentryMiddlewareHandlers from './handlers/sentry';

export const sideEffectsMiddleware: Middleware = (middleware: MiddlewareAPI) => next => (action) => {
  [
    ...kycMiddlewareHandlers,
    ...orderMiddlewareHandlers,
    ...apiKeyMiddlewareHandlers,
    ...cardTokenMiddlewareHandlers,
    ...sentryMiddlewareHandlers,
  ].forEach(handler => handler(middleware, action));

  return next(action);
};

export default sideEffectsMiddleware;
