import * as Sentry from '@sentry/react';

import paymentsApi from 'services/payments';

import { SideEffectsMiddlewareHandler } from './types';

const failedPaymentHandler: SideEffectsMiddlewareHandler = (_, action) => {
  const isRejectedCompleteOrderQuery = paymentsApi.endpoints.completePayment.matchRejected(action);
  if (isRejectedCompleteOrderQuery) {
    // This is to capture every failed payment and hope for
    // customer browser to allow replays. We had issues caused
    // by FE, this is for debugging purposes.
    Sentry.captureMessage(`Failed to complete payment: ${JSON.stringify(action.payload)}`);
  }
};

export const sentryMiddlewareHandlers: SideEffectsMiddlewareHandler[] = [
  failedPaymentHandler,
];

export default sentryMiddlewareHandlers;
