import { tss } from 'theme';
import { FontSize, fontSizeMixin } from 'theme/fonts';

export const useStyles = tss
  .withParams<{hasErrorTitle?: boolean }>()
  .create(
    ({ theme, hasErrorTitle }) => ({
      container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(5),
        height: '100%',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.scale(12),
      },
      imageWrapper: {
        maxWidth: '80%',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: theme.scale(15),
        ...fontSizeMixin(FontSize.M),
        color: hasErrorTitle ? theme.colors.text.primary : theme.colors.text.inactive,
        textAlign: 'center',
        padding: `${theme.scale(15)}px 0`,
        fontWeight: hasErrorTitle ? 600 : 'unset',
      },
      marginTopAuto: {
        marginTop: 'auto',
      },
    }),
  );

export default useStyles;
