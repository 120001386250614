import { NavigationEvaluationFunc, NavigationEvaluationFuncParams } from 'hooks/useNavigation';
import { WorkflowStep } from 'types/step';

export const canGoBackFromTransactionProcessingStep = ({ hasCardError }: NavigationEvaluationFuncParams): boolean => {
  if (hasCardError) {
    return true;
  }

  return false;
};

export const getTransactionProcessingPreviousStep: NavigationEvaluationFunc<WorkflowStep> = () => WorkflowStep.Checkout;

export default getTransactionProcessingPreviousStep;
