import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import COMMON_LABELS from 'constants/messages';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import paymentsApi from 'services/payments';
import { SavedCard } from 'services/payments/types';
import { selectOrderStatus } from 'services/transaction';
import { selectUserCacheEntry } from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import { selectOrder } from 'state/slices/orderSlice';
import {
  selectCardError,
  selectTokenizedCard, setTokenizedCard,
} from 'state/slices/paymentSlice';
import { changeStep, selectIsAddingCard } from 'state/slices/stepSlice';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import { HTTPStatus, ReduxToolkitError } from 'types/http';
import { WorkflowStep } from 'types/step';
import { KycStatus } from 'types/user';

import BankDetails from '.';

export const BankDetailsContainerSell = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken) ?? '';
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const {
    data, isLoading, isError, error,
  } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const { proceed, goBack, canGoBack } = useNavigation();
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken, {
    skip: user?.kycStatus !== KycStatus.Approved,
  });
  const isAddingNewCard = useSelector(selectIsAddingCard);

  const setCard = (tokenizedCard: CardPaymentToken | SavedCard, setCardAsDefault: boolean) => dispatch(setTokenizedCard({
    tokenizedCard,
    rememberCard: true,
    setCardAsDefault,
  }));
  const showErrorScreen = (errorType: ScreenErrorType, isFatalError = false) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, isFatalError, goBackToScreen: WorkflowStep.BankDetails }));
    dispatch(changeStep(WorkflowStep.Error));
  };
  const placedOrderError = useSelector(selectCardError);

  const order = useSelector(selectOrder);
  const orderStatus = useSelector(selectOrderStatus(order.transactionId?.toString() ?? ''));

  const onProceedClick = () => {
    proceed({
      orderStatus,
    });
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    const toolkitError = error as ReduxToolkitError;
    if (!toolkitError?.status && error) {
      throw new Error('Unhandled error: Fetching payment configuration failed');
    }

    if (toolkitError.status === HTTPStatus.Unauthorized) {
      return;
    }
    if (toolkitError.data?.errorType === ApiErrorCode.RegionError) {
      showErrorScreen(ScreenErrorType.RegionUnsupported, true);
      return;
    }

    showErrorScreen(ScreenErrorType.UnableToGetPaymentsConfig);
  }, [error]);

  return (
    <BankDetails
      paymentsConfig={{
        shift4PublicKey: data?.data?.shift4PublicKey,
        merchantId: data?.data?.merchantId,
        isLoading,
        isError,
      }}
      ctaButtonProps={{
        children: t(COMMON_LABELS.ADD_CARD),
      }}
      proceed={onProceedClick}
      goBack={goBack}
      tokenizedCard={tokenizedCard}
      setTokenizedCard={setCard}
      showErrorScreen={showErrorScreen}
      canGoBack={canGoBack}
      orderError={placedOrderError?.title ?? placedOrderError?.message}
      isAddingNewCard={isAddingNewCard}
      cards={{
        cards: cardsResponse ?? [],
        isLoading: areCardsLoading,
        error: cardsError,
      }}
      showSetDefaultControl
    />
  );
};

export default BankDetailsContainerSell;
