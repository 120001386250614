import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import QuoteDetailsSell from 'components/quoteDetails/sell';
import useNavigation from 'hooks/useNavigation';
import getErrorScreenMessage from 'locales/messages/errorScreenMessages';
import useShift4Components from 'pages/bankDetails/useShift4Components';
import paymentsApi, { FINISH_PAYMENT_CACHE_KEY, selectPaymentMethod } from 'services/payments';
import { OrderCompletionPayload, OrderCompletionResponse, SavedCard } from 'services/payments/types';
import quotesApi, { QUOTE_CACHE_KEY, QuoteAction } from 'services/quote';
import { QuotePayload } from 'services/quote/transactions/payloads';
import { selectUserCacheEntry } from 'services/user';
import { selectAuthToken } from 'state/slices/authSlice';
import { setError } from 'state/slices/errorSlice';
import {
  selectCryptoAsset,
  selectFiatAsset,
  selectOrder,
  setExecutedQuoteId,
  setTransactionId,
} from 'state/slices/orderSlice';
import {
  CardError,
  selectCardError,
  selectIsDefaultCard,
  selectTokenizedCard,
  setCardError,
  setPaymentMethod,
  setTokenizedCard,
} from 'state/slices/paymentSlice';
import { changeStep, setIsAddingCard } from 'state/slices/stepSlice';
import { RootState } from 'state/store';
import { CurrencyType, Quote } from 'types/assets';
import { CardPaymentToken } from 'types/card';
import ApiErrorCode from 'types/errors/api';
import { ScreenErrorType } from 'types/errors/errorScreen';
import Flow from 'types/flow';
import { ReduxToolkitError, ReduxToolkitResponse } from 'types/http';
import PaymentMethod from 'types/payment';
import ConfigQueryParam from 'types/queryParams';
import { WorkflowStep } from 'types/step';
import { TransactionId } from 'types/transactions';
import { KycStatus } from 'types/user';
import { formatCryptoAmountWithSymbolAtEnd } from 'utils/currencies';
import getRedirectUrl from 'utils/domain';
import getQueryParam from 'utils/queryParams';
import verify3DS from 'utils/shift4';

import CheckoutPage from '.';
import CardPreview from './components/cardPreview';
import KEYS, { LABELS } from './keys';
import QuoteAdditionalInfoSell from './quoteAdditionalInfo.sell';
import useStyles from './styles';
import getCardErrorTitleMessageId from './utils';

const CheckoutContainerSell = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [quoteMutation, {
    data: quoteData,
    isLoading: quoteIsLoading,
    isError: isQuoteError,
    error: quoteError,
  }] = quotesApi.useGetQuoteMutation({
    fixedCacheKey: QUOTE_CACHE_KEY,
  });
  const [completePaymentMutation, {
    isError: isPaymentError,
    error: paymentError,
    reset: resetPaymentError,
  }] = paymentsApi.useCompletePaymentMutation({
    fixedCacheKey: FINISH_PAYMENT_CACHE_KEY,
  });

  const selectedFiatAsset = useSelector(selectFiatAsset);
  const selectedCryptoAsset = useSelector(selectCryptoAsset);
  const tokenizedCard = useSelector(selectTokenizedCard);
  const order = useSelector(selectOrder);
  const authToken = useSelector(selectAuthToken) ?? '';
  const isCardSetAsDefault = useSelector(selectIsDefaultCard);
  const user = useSelector(selectUserCacheEntry)?.data?.data;
  const isCardManagementVisibleByDefault = Boolean(useSelector(selectCardError));
  const defaultPaymentMethod = useSelector(selectPaymentMethod);
  const paymentMethod = useSelector((state : RootState) => state.payment.paymentMethod);
  const selectedPaymentMethod = paymentMethod ?? defaultPaymentMethod;
  const externalOrderId = getQueryParam(ConfigQueryParam.ExternalOrderId) ?? undefined;
  const externalUserId = getQueryParam(ConfigQueryParam.ExternalUserId) ?? undefined;
  const redirectUrl = getRedirectUrl(getQueryParam(ConfigQueryParam.RedirectUrl));

  const { data } = paymentsApi.useGetPaymentsConfigQuery(authToken);
  const { shift4Utils } = useShift4Components(data?.data?.shift4PublicKey, data?.data?.merchantId);
  const { proceed, goBack, canGoBack } = useNavigation();
  const { data: cardsResponse, isLoading: areCardsLoading, error: cardsError } = paymentsApi.useGetUserCardsQuery(authToken, {
    skip: user?.kycStatus !== KycStatus.Approved,
  });
  const isPayReady = Boolean(shift4Utils && tokenizedCard);

  const setCard = (
    tokenizedCard: CardPaymentToken | SavedCard,
    rememberCard: boolean,
    setCardAsDefault: boolean,
  ) => dispatch(setTokenizedCard({
    tokenizedCard, rememberCard, setCardAsDefault,
  }));

  const withdrawalAddress = order.walletAddress;
  const withdrawalAddressTag = order.tagOrMemo ? order.tagOrMemo : undefined;
  const proceedLabel = order.cryptoAmount && order.selectedCryptoAsset
    ? t(LABELS.SELL_AMOUNT, {
      amount: formatCryptoAmountWithSymbolAtEnd(order.cryptoAmount, order.selectedCryptoAsset),
    })
    : t(LABELS.SELL);

  useEffect(() => {
    fetchQuote();
  }, []);

  useEffect(() => {
    if (isPaymentError) {
      resetPaymentError();
    }
  }, []);

  const completePayment = (newQuoteId: string) => {
    const cardToken = tokenizedCard?.id;

    if (!cardToken) {
      throw new Error('Unable to complete payment. Missing card info.');
    }

    const payload: OrderCompletionPayload = {
      quoteId: newQuoteId,
      paymentToken: cardToken,
      setCardAsDefault: isCardSetAsDefault,
      side: Flow.Sell,
      externalOrderId,
      externalUserId,
      redirectUrl,
    };

    return completePaymentMutation(payload) as Promise<ReduxToolkitResponse<OrderCompletionResponse>>;
  };

  const fetchQuote = () => {
    if (!authToken || !selectedCryptoAsset || !selectedFiatAsset) {
      throw new Error('Missing quote payload');
    }

    const quoteDataWhichDependsOnExchangeDirection: Partial<QuotePayload> = order.exchangeDirection === CurrencyType.Fiat
      ? { fiatAmount: order.fiatAmount }
      : { cryptoAmount: order.cryptoAmount };

    const payload: QuoteAction = {
      payload: {
        cryptoCurrencyCode: selectedCryptoAsset!.symbol,
        fiatCurrencyCode: selectedFiatAsset!.symbol,
        walletAddress: withdrawalAddress,
        walletTag: withdrawalAddressTag,
        side: Flow.Sell,
        paymentMethod: defaultPaymentMethod ?? undefined,
        ...quoteDataWhichDependsOnExchangeDirection,
      },
      includeToken: Boolean(authToken),
    };

    return quoteMutation(payload) as Promise<ReduxToolkitResponse<{ quote: Quote }>>;
  };

  const onContinue = (
    transactionId: TransactionId | undefined,
    quoteId: string | undefined,
    cardError?: CardError,
  ) => {
    // TODO: Do not even call this func if theres no transactionId or quoteId
    if (transactionId) {
      dispatch(setTransactionId(transactionId));
    }

    if (quoteId) {
      dispatch(setExecutedQuoteId(quoteId));
    }

    if (cardError) {
      dispatch(setCardError(cardError));
    }

    proceed();
  };

  const onPaymentMethodPick = (paymentMethod: PaymentMethod | null) => {
    if (!paymentMethod) {
      return;
    }
    dispatch(setPaymentMethod(paymentMethod));
  };

  const onAddCardClick = () => {
    dispatch(setIsAddingCard(true));
    dispatch(changeStep(WorkflowStep.BankDetails));
  };

  const showErrorScreen = (errorType: ScreenErrorType) => {
    const message = getErrorScreenMessage(errorType);
    dispatch(setError({ ...message, goBackToScreen: WorkflowStep.Checkout }));
    dispatch(changeStep(WorkflowStep.Error));
  };

  const onPayClick = async () => {
    if (!shift4Utils || !tokenizedCard) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const quote = await fetchQuote();
    const newQuoteId = quote.data?.data?.quote.quoteId;
    const quoteErrorMessage = quote.error?.data?.errorMessage;

    if (quoteErrorMessage) {
      return;
    }
    if (!newQuoteId) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    try {
      if (!selectedFiatAsset) {
        throw new Error('Unknown fiat currency');
      }

      const threeDSVerifiedToken = await verify3DS(
        shift4Utils,
        tokenizedCard,
        KEYS.OFFRAMP_3DS_AMOUNT,
        selectedFiatAsset,
      );

      if (threeDSVerifiedToken.error) {
        throw threeDSVerifiedToken.error;
      }
    } catch (e) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    let cardPayment;
    try {
      cardPayment = await completePayment(newQuoteId);
    } catch (e) {
      Sentry.captureException(e);
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const transactionId = cardPayment?.data?.data?.orderUuid;
    const quoteId = cardPayment?.data?.data?.quote.quoteId;

    const isError = Boolean(cardPayment?.error);
    const isErrorTypeMeantForDifferentScreen = cardPayment?.error?.data?.errorType
      && KEYS.ALLOWED_CARD_ERRORS.includes(cardPayment.error.data.errorType as ApiErrorCode);

    if (isError && !isErrorTypeMeantForDifferentScreen) {
      showErrorScreen(ScreenErrorType.PaymentCompletionError);
      return;
    }

    const errorTitle = cardPayment.error ? getCardErrorTitleMessageId(cardPayment.error) : undefined;
    const cardError: CardError | undefined = cardPayment.error?.data?.errorMessage ? {
      title: errorTitle ? t(errorTitle) : undefined,
      message: cardPayment.error.data.errorMessage,
    } : undefined;

    // Offramp specific - S4C-804
    const isCardAuthError = cardPayment.error?.data?.errorType === ApiErrorCode.CardAuthorizationFailed;
    if (isCardAuthError) {
      dispatch(setCardError(cardError ?? null));
      dispatch(changeStep(WorkflowStep.BankDetails));
      return;
    }

    onContinue(transactionId, quoteId, cardError);
  };

  return (
    <CheckoutPage
      canGoBack={canGoBack}
      quote={{
        details: quoteData?.data.quote,
        isLoading: quoteIsLoading,
        isError: isQuoteError,
        error: quoteError as ReduxToolkitError,
      }}
      payment={{
        isError: isPaymentError,
        error: paymentError as ReduxToolkitError,
        method: selectedPaymentMethod ?? null,
      }}
      previews={(
        <div className={classes.previews}>
          <CardPreview
            paymentMethod={selectedPaymentMethod}
            onPaymentMethodPick={onPaymentMethodPick}
            label={t(LABELS.SEND_TO)}
            isCardManagementVisibleByDefault={isCardManagementVisibleByDefault}
            cardInfo={tokenizedCard}
            setTokenizedCard={setCard}
            onAddCardClick={onAddCardClick}
            cards={{
              cards: cardsResponse ?? [],
              isLoading: areCardsLoading,
              error: cardsError,
            }}
          />
        </div>
      )}
      additionalInfo={(
        <QuoteAdditionalInfoSell
          isError={isQuoteError}
          isLoading={quoteIsLoading}
          cryptocurrency={selectedCryptoAsset!}
          fiatCurrency={selectedFiatAsset!}
          fetchQuote={fetchQuote}
          price={quoteData?.data?.quote?.cryptoPrice}
          fiatAmount={(quoteData?.data.quote?.fiatAmount ?? '0')}
          refetch={!(paymentError as ReduxToolkitError)?.data?.errorMessage}
        />
      )}
      proceedLabel={proceedLabel}
      selectedFiatAsset={selectedFiatAsset!}
      selectedCryptoAsset={selectedCryptoAsset!}
      goBack={goBack}
      fetchQuote={fetchQuote}
      onPayClick={onPayClick}
      QuoteBreakdown={QuoteDetailsSell}
      isPayReady={isPayReady}
      regulatoryText={t(LABELS.REGULATORY_TEXT_SELL)}
    />
  );
};

export default CheckoutContainerSell;
