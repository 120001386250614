import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectDefaultCardCacheEntry } from 'services/payments';
import { SavedCard } from 'services/payments/types';
import { RootState } from 'state/store';
import { CardPaymentToken } from 'types/card';
import PaymentMethod from 'types/payment';

const SLICE_NAME = 'payment';

export interface CardError {
  title?: string;
  message: string;
}

interface PaymentState {
  paymentMethod: PaymentMethod | null;
  tokenizedCard: CardPaymentToken | SavedCard | null;
  cardError: CardError | null;
  rememberCard: boolean;
  setCardAsDefault: boolean;
}

interface SetTokenizedCardActionPayload {
  tokenizedCard: CardPaymentToken | SavedCard;
  rememberCard?: boolean;
  setCardAsDefault?: boolean;
}

export const initialState: PaymentState = {
  tokenizedCard: null,
  rememberCard: false,
  setCardAsDefault: false,
  paymentMethod: null,
  cardError: null,
};

export const stepSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTokenizedCard: (state, action: PayloadAction<SetTokenizedCardActionPayload | null>) => {
      if (action.payload) {
        state.tokenizedCard = action.payload.tokenizedCard;
        if (action.payload.rememberCard) {
          state.rememberCard = action.payload.rememberCard;
        }
        if (action.payload.setCardAsDefault) {
          state.setCardAsDefault = action.payload.setCardAsDefault;
        }
        return;
      }
      state.tokenizedCard = null;
      state.rememberCard = false;
      state.cardError = null;
    },
    setCardError: (state, action: PayloadAction<CardError | null>) => {
      state.cardError = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
      state.cardError = null;
    },
    resetPaymentState: () => initialState,
  },
});

export const {
  setTokenizedCard,
  setCardError,
  setPaymentMethod,
  resetPaymentState,
} = stepSlice.actions;

export const selectTokenizedCard = (state: RootState) => state.payment.tokenizedCard
  ?? ((selectDefaultCardCacheEntry(state) ?? null) as CardPaymentToken | null);
export const selectRememberCard = (state: RootState) => state.payment.rememberCard;
export const selectIsDefaultCard = (state: RootState) => state.payment.setCardAsDefault;
export const selectCardError = (state: RootState) => state.payment.cardError;
export const selectPaymentMethod = (state: RootState) => state.payment.paymentMethod;

export default stepSlice.reducer;
